class Elementor_Section {

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/section', ($scope) => {
            this._sticky($scope);
        });
    }


    _sticky($scope) {

        if ($scope.hasClass('osf-sticky-active')) {
            let section_offset = $scope.offset().top,
                section_top = 0,
                section_height = $scope.outerHeight();
            let section_width = $scope.outerWidth();

            if($('#wpadminbar').height()){
                section_top = $('#wpadminbar').height();
            }

            if ($(window).width() < 601) {
                section_top = 0;
            }
            $(window).resize(() => {
                section_width = $scope.outerWidth();
                if(!$scope.hasClass('showsticky-show')){
                    section_offset = $scope.offset().top;
                }
                section_top = 0;

                if($('#wpadminbar').height()){
                    section_top = $('#wpadminbar').height();
                }

                if ($(window).width() < 601) {
                    section_top = 0;
                }
            });

            $(window).scroll(() => {
                if($(window).width() >= 768) {
                    section_width = $scope.outerWidth();
                    if ($(window).scrollTop() >= (section_offset + section_height - section_top)) {
                        if (!$scope.hasClass('sticky-show')) {
                            $scope.addClass('sticky-show');
                            $scope.css({
                                top  : section_top,
                                width: section_width,
                            });
                            $("<div class='h-animate'></div>").insertAfter($scope).css({
                                height: section_height
                            });
                        }
                    } else {
                        $scope.removeClass('sticky-show');
                        $scope.css({
                            top: 0,
                        });
                        if ($scope.not('.elementor-section-stretched')) {
                            $scope.css({
                                width: 'auto',
                            })
                        }
                        let $h_animate = $scope.next('.h-animate');
                        $h_animate.remove();
                    }
                }
            });
        }
    }
}

new Elementor_Section();