class Elementor_Testimonial {
    static instance;

    static getInstance() {
        if (!Elementor_Testimonial.instance) {
            Elementor_Testimonial.instance = new Elementor_Testimonial();
        }
        return Elementor_Testimonial.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-testimonials.default', ($scope) => {
            let $carousel = $scope.find('.owl-carousel');
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
                let $image = $scope.find('.elementor-testimonial-image-wrapper');
                let $detail = $scope.find('.elementor-testimonial-details');
                let $itemactive = $scope.find('.owl-item.active .elementor-testimonial-content');
                let $id = $itemactive.data('trigger');
                if($id.length > 0) {
                    $($id).fadeIn().addClass('active');
                }
                $carousel.on('changed.owl.carousel', function (event) {
                    $($image).fadeOut().removeClass('active');
                    $($detail).fadeOut().removeClass('active');
                    let $a = $(event.target).find(".owl-item.active").next();
                    let $itemactive = $a.find('.elementor-testimonial-content');

                    let $id = $itemactive.data('trigger');
                    if($id.length > 0) {
                        $($id).fadeIn().addClass('active');
                    }
                });
                $('.testimonial-control-prev').click(function() {
                    $carousel.trigger('next.owl.carousel');
                });

                $('.testimonial-control-next').click(function() {
                    $carousel.trigger('prev.owl.carousel');
                });
            }
        });
    }
}

Elementor_Testimonial.getInstance();