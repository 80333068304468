class PropertyVariation{
    static instance;

    static getInstance() {
        if (!PropertyVariation.instance) {
            PropertyVariation.instance = new PropertyVariation();
        }
        return PropertyVariation.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-property-variation.default', ( $scope ) => {
            $scope.find('.property-variation-item > td').magnificPopup({
                delegate : 'a',
                type     : 'image',
                tLoading : 'Loading image #%curr%...',
                mainClass: 'mfp-img-mobile',
                gallery  : {
                    enabled           : true,
                    navigateByImgClick: true,
                    preload           : [0, 1]
                },
                image    : {
                    tError  : '<a href="%url%">The image #%curr%</a> could not be loaded.',
                    titleSrc: function (item) {
                        return item.el.attr('title');
                    }
                }
            });
        } );
    }
}
PropertyVariation.getInstance();