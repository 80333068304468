class Elementor_Product {
    static instance;

    static getInstance() {
        if (!Elementor_Product.instance) {
            Elementor_Product.instance = new Elementor_Product();
        }
        return Elementor_Product.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-products.default', ($scope) => {
            let $carousel = $('.woocommerce-carousel', $scope);
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $('ul.products', $carousel).owlCarousel(Elementor_Carousel.setupData(data));
            }
            let $button = $scope.find('a.elementor-button-load-more');
            let $container = $scope.find('.elementor-widget-container .woocommerce ul.products');
            $button.on('click', function (event) {
                event.preventDefault();
                let settings = $button.data('settings');
                $.ajax({
                    url       : osfAjax.ajaxurl,
                    data      : {
                        action: 'osf_ajax_loadmore_products',
                        data  : settings
                    },
                    type      : 'POST',
                    dataType  : 'JSON',
                    beforeSend: function () {
                        $('body').addClass('loading');
                    },
                    success   : function (response) {
                        $container.append($(response.content).find('.products').html());
                        // $button.data("settings", response.settings);
                        if (response.disable) {
                            $button.remove();
                        }
                        $('body').removeClass('loading');
                    }
                });
            });
            let $list_style = $scope.find('.elementor-widget-container .woocommerce ul.products li.product');
            $list_style.addClass('elementor-style');

        });
    }
}

Elementor_Product.getInstance();

